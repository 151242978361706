import * as React from "react";
import Svg, { Circle } from "react-native-svg";
function SvgTarget(props) {
    return (<Svg width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" {...props}>
      <Circle cx={12} cy={12} r={10}/>
      <Circle cx={12} cy={12} r={6}/>
      <Circle cx={12} cy={12} r={2}/>
    </Svg>);
}
export default SvgTarget;
