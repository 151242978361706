import * as React from "react";
import Svg, { Circle, Path } from "react-native-svg";
function SvgShoppingCart(props) {
    return (<Svg width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" {...props}>
      <Circle cx={9} cy={21} r={1}/>
      <Circle cx={20} cy={21} r={1}/>
      <Path d="M1 1h4l2.68 13.39a2 2 0 002 1.61h9.72a2 2 0 002-1.61L23 6H6"/>
    </Svg>);
}
export default SvgShoppingCart;
