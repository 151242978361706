import * as React from "react";
import Svg, { Circle } from "react-native-svg";
function SvgMoreHorizontal(props) {
    return (<Svg width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" {...props}>
      <Circle cx={12} cy={12} r={1}/>
      <Circle cx={19} cy={12} r={1}/>
      <Circle cx={5} cy={12} r={1}/>
    </Svg>);
}
export default SvgMoreHorizontal;
